<template>
  <div class="image-gallery">
    <div
      class="img-container img-container-rounded"
      :style="{ 'background-image': `url('${image}')` }"
    >
      <div class="title">{{ title }}</div>
      <div class="close" @click="$emit('close')">
        <i class="fa fa-close"></i>
      </div>
      <div class="navigation">
        <div class="next" @click="$emit('previous')">
          <i class="fa fa-caret-left"></i>
        </div>
        <div class="prev" @click="$emit('next')">
          <i class="fa fa-caret-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeIndex: {
      type: Number,
      default: null,
    },
    images: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    folder: {
      type: String,
      default: null,
    },
  },
  emits: ["previous", "next", "close"],
  computed: {
    image() {
      return `${this.folder}${this.images[this.activeIndex]}.jpg`;
    },
  },
};
</script>

<style>
</style>